<template>
  <small
    v-html="format(data)"
  ></small>
</template>

<script>
export default {
  data() {
    return {
      data: '',
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
        this.$http.get(this.$api.logs()).then(({ data }) => {
            this.data = data;
        });
    },
    format(data) {
      return data.replaceAll("\n", "<br>");
    },
  }
}
</script>